import React from 'react';

import PageBg from 'img/page-header-bg.jpg';
import styles from './Header.module.css';

interface IPageHeaderProps {
  title: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ title }) => (
  <header
    className={styles.header}
    style={{ backgroundImage: `url("${PageBg}")` }}
  >
    <h1 className={styles.title}>{title}</h1>
  </header>
);

export default PageHeader;
