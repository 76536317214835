import React from 'react';
import map from 'lodash/map';
import { RELEASE_DETAILS_ROUTE } from 'utils/routes';
import { GetReleasesIndexQuery } from 'types';

import PageHeader from 'components/common/PageHeader';
import ReleaseItem from './ReleaseItem';

import styles from './Releases.module.css';

interface IReleasesIndexProps {
  posts: GetReleasesIndexQuery['posts'];
}

export const Releases: React.FC<IReleasesIndexProps> = ({ posts }) => {
  return (
    <>
      <PageHeader title="Releases" />
      <section className={styles.releasesContainer}>
        <div className={styles.releases}>
          {map(posts.edges, ({ node: { title, slug, coverArt } }) => (
            <ReleaseItem
              key={slug}
              title={title}
              to={RELEASE_DETAILS_ROUTE(slug)}
              img={coverArt.fluid}
            />
          ))}
        </div>
      </section>
    </>
  );
};
