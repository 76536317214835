import React from 'react';
import { graphql } from 'gatsby';
import map from 'lodash/map';
import { RELEASES_SEO } from 'utils/seo';

import Layout from 'components/layout';
import { Releases } from 'components/Releases';

import { GetReleasesIndexQuery } from 'types';

interface IReleasesIndexProps {
  data: GetReleasesIndexQuery;
  location: Location;
}

export const ReleasesPage: React.FC<IReleasesIndexProps> = ({
  data: { posts, latestReleases },
}) => {
  const latestReleaseTitles = map(
    latestReleases.edges,
    ({ node: { title } }) => title
  ).join(', ');

  return (
    <Layout
      title={RELEASES_SEO.title}
      description={RELEASES_SEO.description(latestReleaseTitles)}
      pathname={RELEASES_SEO.pathname}
    >
      <Releases posts={posts} />
    </Layout>
  );
};

export default ReleasesPage;

export const releasesIndexQuery = graphql`
  query GetReleasesIndex {
    posts: allContentfulRelease(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          slug
          title
          coverArt {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }

    latestReleases: allContentfulRelease(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
        }
      }
    }
  }
`;
