import React from 'react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import styles from './ReleaseListItem.module.css';

interface IReleaseItemProps {
  to: string;
  title: string;
  img: FluidObject;
}

const ReleaseItem: React.FC<IReleaseItemProps> = ({ to, title, img }) => (
  <article className={styles.release}>
    <div className={styles.releaseImgContainer}>
      <Link to={to} className={styles.releaseImgLinkContainer}>
        <Img
          fluid={img}
          alt={`${title} cover art`}
          className={styles.releaseImg}
          style={{ position: 'absolute' }}
        />
      </Link>
    </div>
  </article>
);

export default ReleaseItem;
